<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        For a pure sample of benzoic acid, the mass
        <stemble-latex content="$\%$" />
        of carbon is
        <stemble-latex content="$68.8$" />
        and the mass
        <stemble-latex content="$\%$" />
        of hydrogen is
        <stemble-latex content="$4.953$." />
        Three analyses were performed on a sample of benzoic acid produced by a chemical company.
        The mean of the results were
        <stemble-latex content="$70\,\%$" />
        C and
        <stemble-latex content="$4.958\,\%$" />
        H.
      </p>

      <p class="mb-2">
        Assuming that s
        <stemble-latex content="$\rightarrow~\sigma~=~0.004~\%$" />
        for carbon and
        <stemble-latex content="$0.006\,\%$" />
        for hydrogen, determine at the
        <stemble-latex content="$95\,\%$" />
        confidence level, if there is any indication of systematic error in the analysis of carbon
        and hydrogen.
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-3"
        prepend-text="$\text{Z-value for carbon:}$"
        :disabled="!allowEditing"
      />

      <calculation-input
        v-model="inputs.input2"
        class="mb-3"
        prepend-text="$\text{Z-value for hydrogen:}$"
        :disabled="!allowEditing"
      />

      <p class="mb-1">Was there a systematic error in the analysis of carbon?</p>
      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="mb-2 pl-6">
        <v-radio v-for="item in items" :key="'pt-1-' + item.value" class="my-1" :value="item.value">
          <template #label>
            <stemble-latex :content="item.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-1">Was there a systematic error in the analysis of hydrogen?</p>
      <v-radio-group v-model="inputs.input4" :disabled="!allowEditing" class="mb-2 pl-6">
        <v-radio v-for="item in items" :key="'pt-2-' + item.value" class="my-1" :value="item.value">
          <template #label>
            <stemble-latex :content="item.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUPEI2210A2Task39',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      items: [
        {text: 'yes', value: 'yes'},
        {text: 'no', value: 'no'},
      ],
    };
  },
};
</script>
<style scoped></style>
